
import { useStore } from "@/store/store";
import { ImmutableXClient } from '@imtbl/imx-sdk';
import { computed, defineComponent, ref } from 'vue';
import WalletConnectionHandler from "../components/WalletConnectionHandler.vue";
import useImmutableX from "../composables/useImmutableX";
import useLoadingHandler from "../composables/useLoadingHandler";
import useNetwork from "../composables/useNetwork";
import useNFTContract from "../composables/useNFTContract";
import { BINANCE_NETWORK_ID, BINANCE_NETWORK_ID_HEX, ETH_NETWORK_ID, IMMUTABLE_X_DOGGGO_FUD_COLLECTION, IMMUTABLE_X_PUBLIC_API_URL } from '../constants';

export default defineComponent({
    name: 'MyCollectionView',
    components: {
        WalletConnectionHandler
    },
    setup: async () => {
        const { toggleLoading } = useLoadingHandler();

        const store = useStore();
        const { doConnectNetwork } = await useNetwork(store);
        await doConnectNetwork();

        const { registerImmutableX } = await useImmutableX();

        const initMessage = ref('');

        const { getNFTReserved } = await useNFTContract(store.state);

        const binanceNetworkID = Number(BINANCE_NETWORK_ID);

        if (localStorage.getItem('address') != null) {
            store.commit("setIMXRegistered", true);
        } else {
            store.commit("setIMXRegistered", false);
        }

        if (store.state.chainId === binanceNetworkID) {
            let myNFTReserved = await getNFTReserved();
            store.commit("setMyNFTReserved", myNFTReserved);
        }

        const requestImmutableXNFTs = async () => {
            if (store.state.currentUser != '') {
                const client = await ImmutableXClient.build({ publicApiUrl: IMMUTABLE_X_PUBLIC_API_URL });
                const assets = await client.getAssets({ user: store.state.currentUser, collection: IMMUTABLE_X_DOGGGO_FUD_COLLECTION, order_by: "name" });
                if (assets.result.length === 0) {
                    initMessage.value = 'Go and buy some FUD NFT';
                } else {
                    localStorage.setItem('address', store.state.currentUser);
                    store.commit("setIMXRegistered", true);
                }
                store.commit("setCurrentUserNFT", assets.result);
            }
        };

        const buyMessage = computed({
            get: () => initMessage.value,
            set: (message) => {
                initMessage.value = message;
            }
        });

        const retryFailedMints = async () => {
        }

        store.watch((state, getters) => getters.currentUser, async () => {
            await requestImmutableXNFTs();
        });

        if (store.state.currentUserNFT.length == 0) {
            await requestImmutableXNFTs();
            if (store.state.isIMXRegistered) {
                await retryFailedMints();
            }
        }

        toggleLoading('main-loader', false);

        return {
            assetResult: computed(() => store.state.currentUserNFT),
            selectedAccount: computed(() => store.state.currentUser),
            chainId: computed(() => store.state.chainId),
            BINANCE_NETWORK_ID,
            buyMessage,
            registerImmutableX,
            isIMXRegistered: computed(() => store.state.isIMXRegistered),
        };
    }
});
